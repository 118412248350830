@import url(https://fonts.googleapis.com/css?family=Roboto);

/*@import "public/fonts/AbrilFatFace-Regular.ttf";
@import "public/fonts/Roboto-Regular.ttf";*/
@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.d41d8cd9.ttf) format("truetype"); }

body {
  font-family: Roboto !important;
  font-size: 15px; }

.block-section {
  padding: 30px 20px; }

@media only screen and (min-width: 900px) {
  .max-h {
    max-height: 350px !important; }
    .max-h img {
      max-height: 350px !important; }
  .max-w {
    max-width: 35% !important; } }

@media only screen and (max-width: 899px) {
  .no-padMobile {
    padding-right: 0 !important;
    padding-left: 0 !important; } }

@media only screen and (min-width: 1300px) {
  .max-w {
    max-width: 40% !important; } }

.box-size {
  height: 250px; }

.logo-size {
  padding: 20px;
  width: 70%; }

.picture-fullsize {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.blueround {
  background: rgba(46, 0, 237, 0.5);
  border-radius: 50%;
  height: auto;
  width: auto;
  padding: 75px; }
  .blueround .nav-head {
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    z-index: -1000; }
  .blueround p {
    max-width: 150px; }

.container {
  padding: 0; }

.creche img {
  max-width: 100%;
  height: auto;
  position: -webkit-sticky;
  position: sticky; }

figcaption {
  text-align: center; }

.container .row {
  margin: 0; }

.question-mail {
  background: rgba(154, 154, 154, 0.44);
  border-radius: .25rem; }

.sizetext {
  font-size: 30px; }

.no-pad {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.ioej {
  display: flex; }

.logo {
  max-width: 400px;
  max-height: 400px;
  margin: auto; }

.logo-pz {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  margin: auto; }

@media only screen and (min-width: 768px) {
  .block-txt-simple {
    background: rgba(154, 154, 154, 0.44);
    border-radius: 30px; }
    .block-txt-simple p {
      font-size: 17px; }
  .block-txt-simple2 {
    background: rgba(154, 154, 154, 0.44);
    border-radius: 30px; } }

@media only screen and (max-width: 576px) {
  header .plan-boy {
    bottom: -10%;
    width: 20px; }
  .col-sm-6 {
    max-height: 40% !important; }
  .block-txt-simple {
    max-height: 35%;
    background: rgba(154, 154, 154, 0.44);
    border-radius: 30px; }
    .block-txt-simple p {
      font-size: 15px; }
  .nav-head {
    font-size: 30px !important; }
  .blueround {
    margin-top: auto;
    bottom: 50px; }
  .sizetext {
    font-size: 18px;
    text-align: center;
    padding: 0; }
  .creche {
    width: 100%; } }

@media only screen and (max-width: 768px) {
  header .plan-boy {
    bottom: -20%;
    right: 5%;
    width: 150px; }
  .no-padding {
    padding: 0; } }

@media only screen and (min-width: 1024px) {
  header .plan-boy {
    width: 200px;
    bottom: -15%; }
  header.bg-header {
    background-size: 100% auto;
    min-height: 600px; } }

footer.block-section {
  background-color: #252525;
  color: white; }

.type--fine-print {
  opacity: .5;
  font-size: .85714286em;
  color: white;
  margin-left: 1.85714286em; }

.underline {
  text-decoration: none; }

.minitext {
  font-size: smaller;
  font-style: italic; }

table {
  width: 80%;
  height: 100px; }

td {
  border: 1px solid black; }

.bg-blue {
  background-color: #2E00ED; }

.btn-md, a.btn-md {
  padding: 15px 25px;
  font-size: 16px; }

.btn, a.btn {
  display: inline-block;
  color: #ffffff;
  padding: 11px 18px;
  margin-bottom: 7px;
  margin-right: 5px;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 1px;
  vertical-align: middle;
  white-space: pre-line;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.btn-ghost:hover {
  background-color: #fff !important;
  color: #69696E !important; }

.btn.focus, .btn:focus, .btn:hover {
  color: #333;
  text-decoration: none; }

.btn-ghost {
  background-color: transparent !important;
  border: 3px solid #fff !important;
  color: #fff; }

.color-mpec {
  color: #2E00ED !important; }

.title {
  color: #2E00ED !important;
  font-weight: bold !important; }

.lh--n {
  line-height: normal; }

.plus {
  text-align: center;
  padding: 20px; }

.margin--p {
  margin-top: 15px; }

ul {
  list-style: none; }

ul li::before {
  content: "\2022";
  color: deepskyblue;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.imgRadius > .cloudimage-image {
  border-radius: 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

